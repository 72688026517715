import React, { useEffect } from 'react'
import clsx from 'clsx'
import { FooterData } from '@/components/layout/AppShell/getFooterData'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { omit, pick } from 'lodash'

import Head from 'next/head'
import { bannerClosed } from 'store/tracking'
import { company, user } from 'store/profile'
import Spinner from '@/components/ui/Spinner/Spinner'
import Alert from '@/components/ui/Alert/Alert'
import IconClose from '@/components/ui/Icon/IconClose'
import Newsletter from '@/components/shared/Newsletter/Newsletter'
import Steps from '@/components/shared/Steps/Steps'
import Button from '@/components/ui/Button/Button'
import HeaderCombined from './AppShell/HeaderCombined'
import Footer from './AppShell/Footer'
import alert from '../../store/global'
import { APP_ROUTES, BASE_URL } from '../../utils/constants'
import auth from '../../api/auth'
import getUser from '../../api/getUser'
import convertKeysToCamelCase from '../../utils/convertKeysToCamelCase'
import useErrorHandler from '../../hooks/useErrorHandler'

interface Props {
  children: React.ReactNode
  isPublic?: boolean
  isPrivate?: boolean
  footerData?: FooterData
  shouldShowHeaderNavigation?: boolean
  shouldShowBannerProps?: boolean
  shouldShowSteps?: boolean
  shouldShowNewsletter?: boolean
  shouldShowFooter?: boolean
}

const Layout: React.FC<Props> = ({
  children,
  isPublic = false,
  isPrivate = false,
  footerData = { areas: [] },
  shouldShowHeaderNavigation = true,
  shouldShowBannerProps = true,
  shouldShowSteps = true,
  shouldShowNewsletter = true,
  shouldShowFooter = true,
}) => {
  const {
    t,
    i18n: { exists },
  } = useTranslation()

  const errorHandler = useErrorHandler()

  const [alertVariable, setAlert] = useRecoilState(alert)
  const [showBanner, setShowBanner] = useRecoilState(bannerClosed)
  const [userVariable, setUserVariable] = useRecoilState(user)
  const setCompanyVariable = useSetRecoilState(company)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setShowBanner(sessionStorage?.getItem('banner_closed') !== 'true')
    }
  }, [setShowBanner])

  const closeBanner = () => {
    setShowBanner(false)
    sessionStorage.setItem('banner_closed', 'true')
  }

  const locationLinkSuffix =
    footerData.areas.length === 1 ? `/${footerData.areas[0].slug}` : undefined

  const { locale, asPath, defaultLocale, query, push } = useRouter()

  const localePrefix = locale !== defaultLocale ? `/${locale}` : ''

  const canonical = `${BASE_URL}${localePrefix}${asPath}`
  const alternateEN = `${BASE_URL}${asPath}`
  const alternateDE = `${BASE_URL}/de${asPath}`

  const shouldShowBanner = shouldShowBannerProps && showBanner && exists('header.banner')

  const authenticate = async (mtoken: string) => {
    try {
      await getUser()
      // We don't expect to new auth of a user with new acc
    } catch (e) {
      try {
        const res = await auth(mtoken)

        if (res.data.status === 'success' && res.data?.data?.user) {
          const authenticatedUser = res.data.data.user

          setUserVariable({
            companyName: '',
            email: '',
            firstName: '',
            jobTitle: '',
            lastName: '',
            locale: '',
            name: '',
            phone: '',
            loggedIn: true,
            requested: true,
            ...convertKeysToCamelCase(omit(authenticatedUser, 'customer')),
          })

          const customer = pick(authenticatedUser, 'customer')

          if (customer?.customer?.legal_address) {
            // @ts-ignore
            setCompanyVariable(convertKeysToCamelCase(customer?.customer?.legal_address))
          }
        } else {
          const text = await errorHandler(res.data, 'Failed to auth with mtoken')
          setAlert({ type: 'error', text })
        }
      } catch (error) {
        // empty
      }
    }
  }

  useEffect(() => {
    if (query.mtoken && typeof query.mtoken === 'string') {
      authenticate(query.mtoken)
    }
  }, [query])

  useEffect(() => {
    if (alertVariable.text?.length) {
      setTimeout(() => {
        setAlert({ ...alertVariable, text: '' })
      }, 2500)
    }
  }, [alertVariable.text])

  return (
    <div className="min-h-screen">
      <Head>
        <link rel="canonical" href={canonical} />
        <link rel="alternate" hrefLang="en" href={alternateEN} />
        <link rel="alternate" hrefLang="de" href={alternateDE} />
        <meta property="og:canonical" content={canonical} />
      </Head>
      {shouldShowBanner && (
        <div className="relative bg-primary-600 px-10 py-4 text-center font-bold">
          {t('header.banner')}
          <button
            type="button"
            onClick={closeBanner}
            className="absolute right-1 top-2 p-2 ring-0 focus:outline-none md:right-2 md:top-2"
          >
            <IconClose
              size="24"
              className="text-gray-900 transition-colors duration-300 ease-in-out hover:text-gray-400"
            />
          </button>
        </div>
      )}
      <HeaderCombined
        showNavigation={shouldShowHeaderNavigation}
        locationLinkSuffix={locationLinkSuffix}
      />

      <main className={clsx(!isPublic && 'mt-6 md:mt-12')}>
        {alertVariable.text && (
          <Alert className="fixed right-4 top-28 z-header my-1 w-1/4" type={alertVariable.type}>
            {alertVariable.text}
          </Alert>
        )}
        {isPrivate && !userVariable.loggedIn ? (
          <div>
            {userVariable.requested ? (
              <div className="space-y-4 text-center">
                <p>{t('auth.notLoggedError')}</p>
                <Button onClick={() => push(APP_ROUTES.AUTHENTICATION)}>
                  {t('auth.openAuthButton')}
                </Button>
              </div>
            ) : (
              <Spinner className="m-auto" />
            )}
          </div>
        ) : (
          children
        )}
      </main>

      {shouldShowSteps && <Steps />}
      {shouldShowNewsletter && <Newsletter />}
      {shouldShowFooter && <Footer data={footerData} />}
    </div>
  )
}

export default Layout
