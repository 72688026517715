import { useTranslation } from 'next-i18next'

import Logger from '../utils/logger'

const useErrorHandler = () => {
  const { t } = useTranslation()

  return async (e: unknown, sentryMessage?: string) => {
    // @ts-ignore
    const errorMessage = e?.message || t('apiErrors.general_error')

    if (sentryMessage) {
      await Logger.log(sentryMessage, 'error', {})
    }

    return errorMessage
  }
}

export default useErrorHandler
