import axios from 'axios'
import csrfTokenHandler from './csrfTokenHandler'
import { API_URL } from '../utils/constants'

const signOut = async () => {
  await csrfTokenHandler()

  return axios.post(`${API_URL}/api/auth/signout`)
}
export default signOut
